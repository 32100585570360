<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md10 lg10>
        <v-sheet color="white" class="pa-2">
          <v-row class="pb-2">
            <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.smAndDown">
              <v-card elevation="0">
                <v-img
                  v-if="this.doctype === 'invoice'"
                  contain
                  height="60px"
                  src="../assets/dian.png"
                />
                <v-card-title>
                  <!-- <h3>
                    <v-select
                      v-model="doctype"
                      hide-details
                      :items="doctypes"
                      item-value="value"
                      item-text="label"
                      label=""
                      placeholder="Tipo Documento"
                      outlined
                      @change="validate_invoice"
                    />
                  </h3> -->
                </v-card-title>
                <v-card-text v-if="!$vuetify.breakpoint.smAndDown">
                  <b>{{ this.store.PartyName }}</b>
                  <br />
                  {{ this.store.store_address + " , " + this.store.store_city }}<br />
                </v-card-text>
              </v-card>

              <v-data-table
                :headers="headers_cart"
                :items="cart"
                :items-per-page="-1"
                class="elevation-1"
                dense
                calculate-widths
                mobile-breakpoint="0"
                item-key="ean"
                :loading="loading_status"
                loader-height="10"
                loading-text="Cargando ..."
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Productos</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn fab icon @click="search_dialog = !search_dialog">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                  </v-toolbar>
                </template>
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-btn fab icon @click="editItem(item)">
                    {{ item.cantidad }}
                  </v-btn>
                </template>
                <template v-slot:[`item.refe`]="{ item }">
                  {{ item.refe }}
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{ "$ " + (item.precio * item.cantidad).toLocaleString(2) }}
                  <br />
                  <small
                    class="red--text text-decoration-line-through"
                    v-if="item.discount > 0"
                    >{{
                      "$ " +
                      ((item.discount + item.precio) * item.cantidad).toLocaleString(2)
                    }}
                  </small>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="delete_item(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-center">
                      {{ sumField(cart, "cantidad").toLocaleString("es") }}
                    </th>
                    <th class="text-right">
                      {{ this.totals.total.toLocaleString("es") }}
                    </th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
              <v-row class="mt-6">
                <v-col cols="6" sm="4">
                  <v-text-field
                    v-model="bags"
                    class="center-input"
                    background-color="#FFE0B2"
                    label="Bolsas"
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-minus"
                    append-icon="mdi-plus"
                    @click:prepend-inner="restQty"
                    @click:append="addQty"
                  />
                </v-col>
                <v-col cols="6" sm="4">
                  <v-text-field
                    v-model="discount"
                    @keyup="discountApply"
                    outlined
                    label="Descuento"
                    class="center-input"
                    background-color="#FFCDD2"
                    type="number"
                    hide-details
                    append-icon="mdi-percent-outline"
                  />
                </v-col>
                <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                  <v-btn
                    class="right-input"
                    @click="payment_dialogo = !payment_dialogo"
                    block
                    color="success"
                    :disabled="validate_invoice()"
                    x-large
                    >{{ "$ " + this.totals.unpaid.toLocaleString(2) }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <PLU
                :cartItemTotal="totals.unpaid"
                @product-selected="add2cart"
                @products_dialog="search_dialog = !search_dialog"
              />
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-data-table
                :headers="headers_payments"
                :items="payments"
                item-key="id"
                hide-default-footer
                mobile-breakpoint="0"
                sort-by="name"
                class="elevation-1"
                dense
                :loading="loading_status"
                loader-height="10"
                loading-text="Cargando ..."
              >
                <template #top>
                  <v-toolbar flat>
                    <v-toolbar-title>Formas de pago</v-toolbar-title>
                    <v-spacer></v-spacer>

                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }" class="pa-0 ma-0">
                  <v-icon small @click="delete_payment(item)"> mdi-delete </v-icon>
                </template>
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-right">
                      {{ sumField(payments, "payment_value").toLocaleString(2) }}
                    </th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
            </v-col> -->
          </v-row>
        </v-sheet>
      </v-flex>
    </v-layout>

    <v-bottom-navigation dark fixed app>
      <v-spacer></v-spacer>
      <!-- <v-form @submit="get_product" class="pa-2">
        <v-text-field
          v-model="sku"
          id="sku"
          ref="skuID"
          label=""
          :loading="loading_status"
          loader-height="10"
          placeholder="Código de Barras"
          hide-details
          dense
          outlined
          autofocus
          prepend-inner-icon="mdi-barcode-scan"
          append-icon="mdi-help"
          @click:append="search_dialog = !search_dialog"
        >
        </v-text-field>
      </v-form> -->
      <v-spacer></v-spacer>
      <v-btn
        class="price"
        dark
        @click="payment_dialogo = !payment_dialogo"
        color="success"
        x-large
        >{{ "$ " + this.totals.unpaid.toLocaleString(2) }}
      </v-btn>
    </v-bottom-navigation>

    <v-snackbar
      v-model="skuError"
      :timeout="timeout_error"
      absolute
      centered
      dark
      color="red"
      elevation="24"
    >
      Producto no encontrado en el inventario ...
    </v-snackbar>

    <v-dialog
      v-model="search_dialog"
      transition="dialog-fade-transition"
      scrollable
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-form v-model="search_valid" ref="searchform" @submit="search_inventory">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar flat>
              <v-toolbar-title>Buscar en el Inventario</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab icon @click="search_dialog = !search_dialog">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers[$vuetify.breakpoint.xsOnly]"
              :items="inventory_lst"
              :items-per-page="5"
              class="elevation-4"
              mobile-breakpoint="0"
              item-key="ean"
              :loading="loading_status"
              loader-height="10"
              loading-text="Cargando ..."
            >
              <template v-slot:top>
                <v-row class="ma-3">
                  <v-col cols="12" md="5">
                    <v-select
                      v-model="filters.field"
                      :items="['CATEGORIA', 'GRUPO', 'MARCA', 'REFE', 'COLOR', 'TALLA']"
                      label="Criterio"
                      placeholder="Seleccionar"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="filters.value"
                      label="Buscar"
                      clearable
                      hide-details="auto"
                      required
                    >
                      <template v-slot:prepend> </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      :disabled="!search_valid"
                      block
                      color="success"
                      x-large
                      type="submit"
                    >
                      Buscar
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.marca`]="{ item }">
                {{ item.marca + "-" + item.refe + "-" + item.color + "-" + item.talla }}
              </template>
              <template
                v-slot:[`item.precio`]="{ item }"
                v-if="$vuetify.breakpoint.xsOnly"
              >
                <v-chip color="green" dark filter pill @click="add2cart(item)">
                  {{ parseFloat(item.precio).toLocaleString("es") }}
                </v-chip>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="add2cart(item)">
                  mdi-plus-box-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-form>
      <v-snackbar
        v-model="productAdded"
        :timeout="timeout"
        absolute
        centered
        dark
        color="orange"
        elevation="24"
      >
        Producto agregado ...
      </v-snackbar>
    </v-dialog>

    <v-dialog v-model="edit_dialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat rounded>
            <v-toolbar-title>Editar producto</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon fab @click="edit_dialog = !edit_dialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-btn class="mx-1 mr-2" fab icon>
                {{ itemEdit.cantidad }}
              </v-btn>
              <v-list-item-content>
                <v-list-item-title v-text="itemEdit.brand" />
                <v-list-item-subtitle
                  class="text--primary"
                  v-text="itemEdit.refe + '-' + itemEdit.colorName + '-' + itemEdit.talla"
                />
              </v-list-item-content>
              <v-list-item-action>
                <span
                  class="red--text text-decoration-line-through"
                  v-if="itemEdit.discount > 0"
                  >{{
                    "$ " +
                    (
                      (itemEdit.discount + itemEdit.precio) *
                      itemEdit.cantidad
                    ).toLocaleString(2)
                  }}
                </span>
                <span v-if="itemEdit.discount > 0">
                  {{ "- " + parseFloat(itemEdit.discount).toLocaleString("es") }}
                </span>
                {{ "$ " + (itemEdit.precio * itemEdit.cantidad).toLocaleString("es") }}
                <br />

                <br />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            @change="updateTotal"
            v-model="itemEdit.cantidad"
            label="Cantidad"
            placeholder="Cantidad"
            class="center-input"
            type="number"
            min="1"
            hide-details
            outlined
          />
          <v-spacer></v-spacer>
          <v-text-field
            v-model="itemEdit.descuento"
            @keyup="itemdiscountApply"
            label="Descuento"
            placeholder="Descuento"
            class="center-input"
            type="number"
            hide-details
            outlined
            append-icon="mdi-percent-outline"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="client_dialog" max-width="600px">
      <v-card
        :loading="loading_status"
        loader-height="10"
        loading-text="Cargando ..."
        class="client-dialog"
      >
        <v-toolbar flat color="transparent" class="client-header">
          <v-toolbar-title class="client-title">
            <span class="gradient-text">Cliente</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="client_dialog = !client_dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="pa-6">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="client.typeid"
                :items="setting['type_document_identifications']"
                item-value="id"
                item-text="name"
                label="Tipo de documento"
                placeholder="Tipo de documento"
                outlined
                dense
                hide-details="auto"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="client.PartyIdentification"
                label="Número de documento"
                ref="idNumber"
                clearable
                outlined
                dense
                hide-details="auto"
                autofocus
                type="number"
                @keyup="validateID"
                @keypress="filter"
                append-icon="mdi-magnify"
                @click:append-outer="get_rut"
                @click:clear="clearClient"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="client.PartyName"
                label="Nombre Completo"
                outlined
                dense
                hide-details="auto"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="client.client_email"
                label="Correo Electrónico"
                placeholder="Correo Electrónico"
                outlined
                dense
                hide-details="auto"
                type="email"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="client.client_phone"
                label="Celular"
                outlined
                dense
                hide-details="auto"
                type="number"
                @keypress="filter(event)"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="client.AddressLine"
                label="Dirección"
                outlined
                dense
                hide-details="auto"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                v-model="client.CityName"
                :items="setting['municipalities']"
                item-value="id"
                item-text="name"
                label="Ciudad"
                placeholder="Ciudad"
                outlined
                dense
                hide-details="auto"
                class="mb-4"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            large
            type="button"
            @click="save_client"
            class="px-8 gradient-button"
          >
            <v-icon left>mdi-content-save</v-icon>
            Guardar Cliente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="payment_dialogo"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      transition="dialog-fade-transition"
      scrollable
    >
      <v-form v-model="payvalid" ref="payform" @submit="addPayment">
        <v-card elevation="1" class="pa-2">
          <v-card-title class="pa-0">
            <v-toolbar flat dense color="transparent" class="checkout-header">
              <v-toolbar-title class="checkout-title">
                <span class="gradient-text">Checkout</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="payment_dialogo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="pa-2">
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center mb-2">
                  <h3 class="subtitle-1 font-weight-medium">Customer Information</h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    outlined
                    color="primary"
                    @click="client_dialog = !client_dialog"
                    class="px-2"
                  >
                    <v-icon left small>mdi-magnify</v-icon>
                    Select Client
                  </v-btn>
                </div>
                <v-card outlined class="customer-info pa-4 mb-6" v-if="client.PartyName">
                  <div class="d-flex align-center mb-2">
                    <v-avatar color="primary" size="32" class="mr-3">
                      <span class="white--text headline">{{
                        client.PartyName.charAt(0)
                      }}</span>
                    </v-avatar>
                    <div class="body-1 font-weight-medium">{{ client.PartyName }}</div>
                  </div>
                  <div class="caption text--secondary mb-1">
                    {{ get_name_typeId(client.typeid) }}: {{ client.PartyIdentification }}
                  </div>
                  <div class="caption text--secondary mb-1">
                    {{ client.AddressLine }}, {{ get_name_city(client.CityName) }}
                  </div>
                  <div class="caption text--secondary">{{ client.client_email }}</div>
                </v-card>
                <v-card outlined class="customer-info pa-2" v-else>
                  <v-skeleton-loader type="list-item-avatar-two-line" />
                </v-card>
              </v-col>
              <v-col cols="12">
                <h3 class="subtitle-1 font-weight-medium mb-3">Payment Details</h3>
                <v-select
                  v-model="vendor"
                  :items="vendors"
                  item-value="codigo"
                  item-text="nombre"
                  label="Vendor"
                  outlined
                  dense
                  required
                  hide-details="auto"
                  @change="validate_invoice"
                  :error="!validations[4].status"
                  :error-messages="validations[4].label"
                  class="mb-4"
                />

                <v-select
                  v-model="pay_mode"
                  :items="FP"
                  return-object
                  item-value="codigo"
                  item-text="valor"
                  label="Payment Method"
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-4"
                />

                <v-text-field
                  v-model="payment.payment_reference"
                  label="Reference / Document Number"
                  :error="payRefe"
                  @input="updateRefe"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-4"
                />

                <v-text-field
                  v-model="payment.payment_value"
                  label="Amount"
                  type="number"
                  prefix="$"
                  :error="payValue"
                  :rules="payRule"
                  clearable
                  outlined
                  dense
                  required
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!payvalid && validate_invoice()"
              color="primary"
              depressed
              large
              type="submit"
              class="px-8 gradient-button"
            >
              <v-icon left>mdi-check-circle</v-icon>
              Complete Payment
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="st_dialog" max-width="800px">
      <v-card>
        <v-tabs show-arrows centered class="fixed-top-b">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="menu in menus"
            :key="menu"
            :href="'#tab-' + menu"
            @click="changeMenu(menu)"
          >
            {{ menu }}
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-row class="mt-6">
            <v-col
              class="col-6 col-sm-6 col-md-4"
              v-for="product in menu_selected"
              :key="product.ean"
            >
              <v-card height="100%" elevation="8" @click="add2cart_st(product.ean)">
                <v-img
                  cover
                  aspect-ratio="1"
                  max-height="300px"
                  :src="getImageURL(product.marca, product.refe)"
                >
                </v-img>
                <v-card-title dark>
                  <v-select
                    :items="get_options(product.options)"
                    label="Opciones"
                    item-value="ean"
                    item-text="description"
                  ></v-select>
                </v-card-title>
                <v-card-actions dark class="pa-2 card-actions bgb">
                  <v-spacer></v-spacer>
                  $ {{ product.precio }}
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <docViewer
      :item="item"
      :dialog="docViewdialog"
      :newInvoice="nInvoice"
      @close="docViewdialog = false"
      @refresh="refresh_view()"
    />
  </v-container>
</template>

<script>
import { webserver, fe_ws } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import createClient from "../utils/createClient.js";
import createPayment from "../utils/createPayment.js";
import dianResponse from "../utils/dianResponse.js";
import docViewer from "../components/doc_viewer.vue";
import createDoc from "../utils/create_doc.js";
import settings from "../utils/settings.js";
import createCompany from "../utils/createCompany.js";
import PLU from "./PLU.vue";

export default {
  name: "Home",
  components: { docViewer, PLU },
  beforeMount() {
    this.get_data_company();
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  mounted() {
    //document.getElementById("sku").focus();
    this.store = window.store;
    this.get_vendors();
    this.FP = window.settings.FP;
    this.CT = window.settings.CATEGORIA;
    this.MR = window.settings.MARCA;
    this.colors = window.settings.COLOR;
    this.seller = window.seller;
    window.addEventListener("keypress", this.handleKeyPress);
  },
  data() {
    return {
      docViewdialog: false,
      isScanning: false,
      scanTimeout: null,
      barcode: "",
      company: createCompany(),
      doctype: "invoice",
      nInvoice: false,
      store: createClient(),
      connection: null,
      loading_status: false,
      loading_vendors: false,
      itemEdit: createItem(),
      editvalid: false,
      skuError: false,
      timeout: 250,
      timeout_error: 1000,
      edit_dialog: false,
      valid: false,
      productAdded: false,
      payvalid: false,
      search_valid: false,
      client_dialog: false,
      payment_dialogo: false,
      search_dialog: false,
      showPrint: false,
      filters: { categoria: "", marca: "", refe: "", id_pos: "" },
      sku: null,
      cart: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        codigo: "01",
        valor: "EFECTIVO",
      },
      payment: createPayment(),
      topay: true,
      discoutRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 50) return true;
        return "Debe ser un numero entre 0 y 50";
      },
      requiredRule: [(v) => (v && v !== null) || "Forma de pago requerida"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      client: createClient(),
      setting: settings(),
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      doctypes: [
        { value: "pos", label: "POS - Documento Equivalente" },
        { value: "invoice", label: "Factura Electrónica" },
      ],
      FP: null,
      CT: null,
      MR: null,
      colors: [],
      headers: {
        false: [
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "categoria",
            dataType: "text",
          },
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "grupo",
            dataType: "text",
          },

          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "marca",
            dataType: "text",
          },

          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "precio",
            dataType: "number",
          },
          {
            text: "Descuento",
            align: "end",
            sortable: true,
            value: "descuento",
            dataType: "number",
          },
          { text: "", value: "actions", sortable: false },
        ],
        true: [
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "marca",
            dataType: "text",
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "precio",
            dataType: "number",
          },
        ],
      },
      headers_cart: [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Qty",
          align: "center",
          sortable: true,
          value: "cantidad",
          dataType: "text",
          width: "10",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      headers_payments: [
        {
          text: "Concepto",
          align: "start",
          value: "payment_concept",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      inventory_lst: [],
      vendors: [],
      vendor: null,
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      bags: 0,
      discount: null,
      dataURL: null,
      invoiceImg: null,
      st_items: [],
      itemsby: null,
      menus: null,
      menu_selected: null,
      menuName: null,
      st_dialog: false,
      eInvoice: null,
      seller: null,
      dian_dialog: false,
      dian_response: dianResponse(),
      docview: "",
      dialog: false,
      item: createDoc(),
      validations: [
        { status: false, label: "Deme suministrar nombre del cliente" },
        { status: false, label: "Correo electrónico es requerido" },
        { status: false, label: "Documento de identificación del Cliente es requerido" },
        { status: false, label: "El tipo de documento es requerido" },
        { status: true, label: "Vendor es requerido" },
        { status: false, label: "Requiere Factura Electrónica" },
        { status: false, label: "Valor" },
        { status: false, label: "Ciudad es requerido" },
      ],
      validForm: false,
      qtyRules: [
        (v) => !!v || "Cantidad es requerida",
        (v) => (v && v >= 0) || "No puede ser cero",
      ],
    };
  },
  methods: {
    validateID(e) {
      if (e.keyCode === 13) {
        this.get_rut();
      }
    },
    clearClient() {
      this.client = createClient();
      this.client.CityName = this.company.city_code;
    },
    get_rut() {
      this.loading_status = true;
      var qry = {
        id_company: this.company.PartyIdentification,
        method: "POST",
        nit: this.client.PartyIdentification,
        data: [],
      };
      this.loading_status = true;
      //console.log(qry);
      fe_ws("get_rut", qry, (data) => {
        this.loading_status = false;
        var rut = JSON.parse(data);
        //console.log(rut);
        if (rut.business_name) {
          this.client.PartyName = rut.business_name;
          this.client.typeid = "6";
          this.get_fe_email();
        } else {
          if (rut.first_name) {
            this.client.PartyName =
              rut.first_name + " " + rut.surname + " " + rut.second_surname;
            this.client.typeid = "3";
            this.get_fe_email();
          } else {
            this.client = createClient();
            this.client.CityName = this.company.city_code;
          }
        }
      });
    },
    get_fe_email() {
      this.loading_status = true;
      var qry = {
        id_company: this.company.PartyIdentification,
        method: "POST",
        nit: this.client.PartyIdentification,
        data: [],
      };
      this.loading_status = true;
      // //console.log(qry);
      fe_ws("get_rut_email", qry, (data) => {
        this.loading_status = false;
        // //console.log(data);
        var rut_email = JSON.parse(data);
        if (rut_email.email) {
          this.client.client_email = rut_email.email;
          this.get_client(this.client.PartyIdentification, false);
        } else {
          // this.client.client_email = "";
          this.get_client(this.client.PartyIdentification, true);
        }
      });
    },
    get_client(e, mail) {
      //e.preventDefault();
      var qry = {
        store: window.store.store_id,
        PartyIdentification: this.client.PartyIdentification,
      };
      webserver("get_client_info", qry, (data) => {
        // //console.log(data);
        //this.client = data;
        //this.loading_status = false;
        if (mail && data.client_email && data.client_email !== "") {
          this.client.client_email = data.client_email;
        } else {
          if (mail) this.client.client_email = "";
        }
        if (data.AddressLine && data.AddressLine !== "") {
          this.client.AddressLine = data.AddressLine;
        } else {
          this.client.AddressLine = "";
        }
        if (data.client_phone && data.client_phone !== "") {
          this.client.client_phone = data.client_phone;
        } else {
          this.client.client_phone = "";
        }
        if (
          data.CityName &&
          data.CityName !== "" &&
          this.containsOnlyNumbers(data.CityName)
        ) {
          this.client.CityName = data.CityName;
        } else {
          this.client.CityName = this.company.city_code;
        }
      });

      //console.log(e);
    },
    get_data_company() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "PartyIdentification",
            operator: "=",
            value: window.store.store_business_id,
          },
        ],
      };
      this.loading_status = true;

      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        //console.log("get_data_company", data);
        if (data.length > 0) {
          this.company = data[0];
          this.client.CityName = this.company.city_code;
        }
      });
    },
    validate_doctype() {
      if (this.totals.total > 280037 && this.doctype === "pos") {
        this.doctype = "invoice";
      }
      //console.log(e);
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumFieldPrice(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    getImageURL: function (marca, refe) {
      var account = window.store.store_id.substring(0, 6);
      var url =
        "https://moda-stc.com/photos/" + account + "/" + marca + "/" + refe + ".jpg";
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    scroll() {
      document.getElementById("scroll-target").scrollTop = document.getElementById(
        "scroll-target"
      ).scrollHeight;
    },
    editItem(e) {
      this.itemEdit = e;
      this.edit_dialog = true;
    },
    itemUpdate(e) {
      e.preventDefault();
      this.$refs.editform.validate();
      if (this.editvalid) {
        //console.log("Saving");
      }
    },
    get_name_typeId(e) {
      var index = this.setting["type_document_identifications"].findIndex(
        (itm) => itm.id === e
      );
      if (index >= 0) {
        return this.setting["type_document_identifications"][index].name;
      } else {
        return "";
      }
    },
    get_name_city(e) {
      var index = this.setting["municipalities"].findIndex((itm) => itm.id === e);
      if (index >= 0) {
        return this.setting["municipalities"][index].name;
      } else {
        return "";
      }
    },
    restQty() {
      if (this.bags > 0) {
        this.bags = this.bags - 1;
      }
      // this.setSku();
    },
    addQty() {
      this.bags = this.bags + 1;
      //   this.setSku();
    },
    add2cart_st(data) {
      //console.log(data);
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: {
          id_pos: window.store.store_id,
          ean: data,
        },
      };
      webserver("get_catalog", qry, (data) => {
        if (data[0]) {
          this.add2cart(data[0]);
        } else {
          this.skuError = true;
        }
      });
    },
    get_product(e) {
      e.preventDefault();
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: [
          {
            field: "id_pos",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "ean",
            operator: "=",
            value: this.sku,
          },
        ],
      };

      this.sku = null;
      //console.log("get_product", qry);
      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        if (data[0]) {
          this.add2cart(data[0]);
        } else {
          this.skuError = true;
        }
      });
    },
    get_id_barcode(e) {
      //var temp = idCliente.split('\t');
      var temp = e.split(";");
      var u_id = temp[0].trim();
      var u_lastname = (temp[1].trim() + " " + temp[2].trim()).trim();
      var u_firstname = (temp[3].trim() + " " + temp[4].trim()).trim();
      var u_name = (u_firstname + " " + u_lastname).trim();
      var birthday = null;
      var sx = null;
      if (temp[5] === "0M" || temp[5] === "0F") {
        sx = temp[5].replace(/0/g, "");
        birthday =
          temp[6].substring(0, 4) +
          "-" +
          temp[6].substring(4, 6) +
          "-" +
          temp[6].substring(6, 8);
      }
      if (temp[5] === "M" || temp[5] === "F") {
        sx = temp[5];
        birthday =
          temp[6].substring(4, 8) +
          "-" +
          temp[6].substring(2, 4) +
          "-" +
          temp[6].substring(0, 2);
      }
      this.client.PartyIdentification = u_id;
      this.client.PartyName = u_name;
      this.client.client_birthday = birthday;
      this.client.sex = sx;
    },
    discountApply(e) {
      if (e.keyCode === 13) {
        this.cart.forEach((itm) => {
          itm.descuento = parseFloat(this.discount);
          if (itm.discount && itm.discount > 0) {
            itm.precio = itm.precio + itm.discount;
          }
          var factor = parseFloat(itm.descuento) / 100;
          itm.discount = Math.round(itm.precio * factor);
          itm.precio = parseInt(itm.precio - itm.discount);
        });
        this.discount = null;
        // this.setSku();
        this.updateTotal();
      }
    },
    itemdiscountApply(e) {
      if (e.keyCode === 13) {
        if (
          this.itemEdit.tax === "" ||
          this.itemEdit.tax === null ||
          this.itemEdit.tax === undefined
        ) {
          this.itemEdit.tax = 19;
        }
        this.itemEdit.precio =
          parseFloat(this.itemEdit.precio) + parseFloat(this.itemEdit.item_discount);
        if (this.itemEdit.descuento === "") {
          this.itemEdit.descuento = 0;
        } else {
          this.itemEdit.descuento = parseFloat(this.itemEdit.descuento);
        }
        var tax_value = parseFloat(this.itemEdit.tax) / 100 + 1;
        if (
          parseInt(this.itemEdit.descuento) > 0 &&
          parseInt(this.itemEdit.descuento) <= 100
        ) {
          var factor = parseInt(this.itemEdit.descuento) / 100;
          this.itemEdit.discount = Math.round(this.itemEdit.precio * factor);
          this.itemEdit.item_discount = this.itemEdit.discount.toFixed(2);
        } else {
          this.itemEdit.discount = parseInt(this.itemEdit.descuento);
          this.itemEdit.item_discount = this.itemEdit.discount.toFixed(2);
        }
        this.itemEdit.precio = this.itemEdit.precio - this.itemEdit.discount;
        this.itemEdit.price_amount = Math.round(
          parseFloat(this.itemEdit.precio) / tax_value
        );
        this.itemEdit.item_tax = this.itemEdit.precio - this.itemEdit.price_amount;
        this.updateTotal();
      }
    },
    add2cart(e) {
      console.log(e);
      const nItm = { ...e };
      var STC = 1;
      nItm.qty = parseFloat(nItm.cantidad);
      nItm.precio = parseFloat(nItm.precio) * STC;
      if (nItm.tax === "" || nItm.tax === null || nItm.tax === undefined) {
        nItm.tax = 19;
      }
      if (nItm.cantidad === null) {
        nItm.cantidad = 1;
      } else {
        nItm.cantidad = parseFloat(nItm.cantidad);
      }
      var tax_value = parseFloat(nItm.tax) / 100 + 1;
      if (parseInt(nItm.descuento) > 0 && parseInt(nItm.descuento) <= 100) {
        var factor = parseInt(nItm.descuento) / 100;
        nItm.discount = Math.round(nItm.precio * factor);
        nItm.item_discount = nItm.discount.toFixed(2);
      } else {
        nItm.discount = parseInt(nItm.descuento);
        nItm.item_discount = nItm.discount.toFixed(2);
      }
      nItm.precio = nItm.precio - nItm.discount;

      nItm.price_amount = Math.round(parseFloat(nItm.precio) / tax_value);
      nItm.item_tax = nItm.precio - nItm.price_amount;

      var index = this.cart.findIndex((itm) => itm.ean === nItm.ean);
      if (index === -1) {
        this.cart.push(nItm);
      } else {
        this.cart[index].cantidad += nItm.cantidad;
      }
      this.productAdded = true;
      this.updateTotal();
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addPayment(e) {
      e.preventDefault();
      if (this.pay_mode) {
        if (this.pay_mode.codigo === "01") {
          if (this.payment.payment_value > this.totals.unpaid) {
            var cmb =
              parseFloat(this.payment.payment_value) - parseFloat(this.totals.unpaid);
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payment.payment_value = this.totals.unpaid;
            this.payments.push({ ...this.payment });

            var cambio = {
              payment_concept: "EFECTIVO",
              payment_code: "01",
              payment_reference: " Cambio $ " + cmb,
              payment_value: 0,
            };
            this.payments.push({ ...cambio });
            this.updateTotal();
          } else {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
          }
          this.pay_mode = {
            codigo: "01",
            valor: "EFECTIVO",
          };
        } else {
          if (this.payment.payment_value <= this.totals.unpaid) {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
            this.pay_mode = {
              codigo: "01",
              valor: "EFECTIVO",
            };
          } else {
            this.payValue = true;
            this.payment.payment_value = 0;
          }
        }
      }
    },
    delete_payment(e) {
      var index = this.payments.indexOf(e);
      if (index !== -1) {
        this.payments.splice(index, 1);
      }
      this.updateTotal();
      //this.setSku();
    },
    delete_item(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      // this.setSku();
      this.updateTotal();
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    validate_invoice() {
      var rspns = false;

      if (this.client.CityName) {
        if (this.containsOnlyNumbers(this.client.CityName)) {
          this.validations[7].status = true;
        } else {
          this.validations[7].status = false;
        }

        // rspns = true;
      } else {
        this.validations[7].status = false;
        // rspns = false;
      }

      if (this.totals.total > 0) {
        this.validations[6].status = true;
        //rspns = true;
      } else {
        this.validations[6].status = false;
        //rspns = false;
      }

      if (this.totals.total > 280037 && this.doctype === "pos") {
        this.validations[5].status = false;
        //rspns = true;
      } else {
        this.validations[5].status = true;
        //rspns = false;
      }

      if (this.vendor) {
        this.validations[4].status = true;
        this.validations[4].label = "";
      } else {
        this.validations[4].status = false;
        this.validations[4].label = "Vendedor es Requerido";
        // rspns = true;
      }

      if (this.client.typeid) {
        this.validations[3].status = true;
        // rspns = true;
      } else {
        this.validations[3].status = false;
        // rspns = false;
      }

      if (this.client.PartyIdentification) {
        this.validations[2].status = true;
        // rspns = true;
      } else {
        this.validations[2].status = false;
        //rspns = false;
      }
      if (this.client.client_email) {
        var emailTest = String(this.client.client_email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        ////console.log(emailTest);
        if (emailTest) {
          this.validations[1].status = true;
        } else {
          this.validations[1].status = false;
        }

        // rspns = true;
      } else {
        if (this.client.PartyName === "CONSUMIDOR FINAL") {
          this.validations[1].status = true;
        } else {
          this.validations[1].status = false;
        }
        // rspns = false;
      }

      if (this.client.PartyName !== "") {
        this.validations[0].status = true;
        //rspns = true;
      } else {
        this.validations[0].status = false;
        // rspns = false;
      }

      // var rspns=true;

      // if (this.totals.total>0 ) {

      //   rspns = false;
      //  } else {

      //   rspns = true;
      //  }
      // var newArray = this.validations.filter(function (el) {
      //   return el.status = true;
      // });

      this.validations.forEach((itm) => {
        ////console.log(itm);

        if (!itm.status) {
          rspns = true;
        }
      });
      return rspns;
    },
    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      this.cart.forEach((itm) => {
        this.totals.qty += parseFloat(itm.cantidad);
        this.totals.total +=
          parseFloat(itm.cantidad) * parseFloat(itm.precio) + this.bags * 66;
        this.totals.tax +=
          (parseFloat(itm.cantidad) * parseFloat(itm.precio) * parseFloat(19)) / 100;
      });

      this.payments.forEach((itm) => {
        if (itm.payment_code !== "00") {
          this.totals.paid += parseFloat(itm.payment_value);
        }
      });

      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;
      if (this.totals.unpaid === 0 && this.totals.total > 0) {
        this.payment_dialogo = false;
        this.topay = true;
        this.save_invoice();
      } else {
        this.topay = false;
      }
      if (this.totals.total > 280037 && this.doctype === "pos") {
        this.doctype = "invoice";
      }
    },
    get_vendors() {
      var qry = {
        store: window.store.store_id,
      };
      this.loading_vendors = true;
      webserver("vendors", qry, (data) => {
        this.loading_vendors = false;
        //console.log(data);
        data.forEach((itm) => {
          itm.nombre = itm.nombre.toUpperCase();
        });
        window.vendors = data;
        this.vendors = data;
      });
    },
    setSku() {
      setTimeout(() => {
        // document.getElementById("sku").focus();
      }, 50);
    },

    resetInvoice() {
      this.filters = { categoria: "", marca: "", refe: "", id_pos: "" };
      this.bags = 0;

      this.client = createClient();
      this.payment = createPayment();
      this.cart = [];
      this.payments = [];
      this.client.CityName = this.company.city_code;
      this.updateTotal();
    },

    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },
    search_inventory(e) {
      e.preventDefault();
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
      };
      qry.filters = [
        {
          field: "id_pos",
          operator: "=",
          value: window.store.store_id.slice(0, 6),
        },
        {
          field: this.filters.field,
          operator: " LIKE ",
          value: this.filters.value,
        },
      ];

      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        //console.log(data);
        this.inventory_lst = data;
      });
    },
    get_store_ls() {
      var qry = {
        store: window.store.store_id,
      };
      this.loading_status = true;
      webserver("get_inventory_st", qry, (data) => {
        this.loading_status = false;
        this.st_items = data;
      });
    },
    changeMenu(e) {
      this.menu_selected = this.itemsby[e];
      //console.log(this.menu_selected);
      this.menuName = e;
    },
    get_options(e) {
      return this.groupBy(e.split(","));
    },
    groupBy(arr) {
      var group = [];
      var option = { ean: null, description: null };
      for (let i = 0; i < arr.length - 1; i += 3) {
        //console.log(arr[i]);
        var color = "";
        if (this.colors[arr[i + 1]]) {
          color = this.colors[arr[i + 1]].valor;
        } else {
          color = arr[i + 1];
        }
        option = { ean: arr[i], description: color + " - " + arr[i + 2] };
        group.push(option);
        ////console.log(option);
      }
      return group;
    },
    save_client() {
      var qry = {
        store: window.store.store_id,
        party: this.client,
      };
      webserver("put_client_info", qry, () => {
        //this.resetDoc();
        //this.loading_vendors = false;
        this.client_dialog = false;
        //console.log(data);
        //this.print_doc(data);
      });
    },
    save_invoice() {
      var invoice = {
        buyer: this.client,
        doc: {
          doc_type: this.doctype,
          doc_value: this.totals.paid,
          store: window.store.store_id,
          userId: window.profile.user_email,
          vendor: this.vendor,
        },
        items: this.cart,
        payments: this.payments,
        seller: window.seller,
        store: window.store.store_id,
      };
      ////console.log("post_invoice", invoice);
      this.loading_status = true;
      webserver("post_invoice", invoice, (data) => {
        this.loading_status = false;
        // //console.log(data);
        this.item = { ...data };
        this.docViewdialog = true;
        this.nInvoice = true;
        // this.save_client();
        this.resetInvoice();
        //this.print_doc(data);
        this.loading_vendors = false;
      });
    },
    refresh_view() {
      var e = this.item;
      var issudate = e.issuedate.split(" ");
      var doc = {
        store: e.store,
        issueDate: issudate[0],
        did: e.doc_number,
      };
      this.loading_status = true;
      webserver("get_invoice_fe", doc, (data) => {
        this.loading_status = false;
        this.item = data;
        this.docViewdialog = true;
        this.loading_vendors = false;
      });
    },
    handleKeyPress(event) {
      if (!this.isScanning) {
        this.isScanning = true;
        this.sku = "";
      }
      clearTimeout(this.scanTimeout);
      // Agrega el caracter a barcode
      if (event.key === "Enter") {
        this.get_barcode(this.barcode);
        this.barcode = "";
        this.isScanning = false;
        return;
      }
      this.barcode += event.key;
      this.scanTimeout = setTimeout(() => {
        this.isScanning = false;

        ////console.log("Código de Barras Escaneado:", this.barcode);
        // Aquí puedes manejar el código de barras escaneado
      }, 100); // Ajusta el tiempo de espera según sea necesario
    },
    get_barcode(e) {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: [
          {
            field: "id_pos",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "ean",
            operator: "=",
            value: e,
          },
        ],
      };

      this.sku = null;
      //console.log("get_product", qry);
      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        if (data[0]) {
          this.add2cart(data[0]);
        } else {
          this.skuError = true;
        }
      });
    },
  },

  watch: {
    bags: function () {
      var idx = this.payments.findIndex((itm) => itm.payment_code === "00");
      if (idx === -1) {
        this.payments.push({
          payment_code: "00",
          payment_concept: "ICB ley 1819 de 2016",
          payment_reference: "",
          payment_value: 66 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = 66 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }
      // idx = this.payments.findIndex((itm) => itm.payment_code === "000");
      // if (idx === -1) {
      //   this.payments.push({
      //     payment_code: "000",
      //     payment_concept: "Descuento Impuesto Bolsas",
      //     payment_reference: "",
      //     payment_value: -66 * this.bags,
      //   });
      // } else {
      //   this.payments[idx].payment_value = -66 * this.bags;
      //   if (this.bags <= 0) {
      //     this.payments.splice(idx, 1);
      //   }
      // }

      // if (this.bags === 0) {
      //   idx = this.payments.findIndex((itm) => itm.payment_code === "00");
      //   this.payments.splice(idx, 1);
      //   idx = this.payments.findIndex((itm) => itm.payment_code === "000");
      //   this.payments.splice(idx, 1);
      //   this.updateTotal();
      // }
      this.updateTotal();
    },
    client_dialog(visible) {
      if (!visible) {
        //console.log(visible);
        //this.setSku();
      }
    },
    pay_mode() {
      if (this.pay_mode.codigo === "01") {
        this.payRefe = false;
      } else {
        this.payRefe = true;
      }
      ////console.log(this.payRefe);
    },

    st_items: function (val) {
      var res = val.reduce(function (r, a) {
        r[a.category] = r[a.category] || [];
        r[a.category].push(a);
        return r;
      }, Object.create(null));
      this.itemsby = res;
      this.menus = Object.keys(res);
      if (this.menuName) {
        this.menu_selected = res[this.menuName];
      } else {
        this.menu_selected = res[Object.keys(res)[0]];
      }
    },
  },
  updated() {
    //this.scroll();
  },
  computed: {},
};
</script>

<style lang="scss">
.bg-pos {
  //  background: url("../assets/login.jpg") no-repeat center center fixed;
  background: #cfd8dc;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.fixed-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.center-input input {
  text-align: center;
  width: 10px;
}

.right-input input {
  text-align: right;
  width: 10px;
}

.scroll {
  overflow-y: scroll;
  max-height: 90vh;
}

.cart-list {
  flex-grow: 1;
  overflow: auto;
  height: 60vh;
}
.price {
  font-size: 1.5em !important;
  font-weight: bold !important;
}
</style>

<style scoped>
.checkout-dialog {
  border-radius: 8px;
}
.checkout-header {
  height: 64px;
}
.checkout-title {
  font-size: 24px;
  font-weight: 600;
}
.gradient-text {
  background: linear-gradient(45deg, #6772e5, #3ecf8e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.gradient-button {
  background: linear-gradient(45deg, #6772e5, #828bec) !important;
  transition: opacity 0.3s ease;
  color: white !important;
}
.gradient-button:hover {
  opacity: 0.9;
}
.customer-info {
  background-color: #f9fafc;
  border-radius: 8px;
  border-color: #e0e0e0;
}
.v-text-field ::v-deep input,
.v-select ::v-deep .v-select__selection {
  font-size: 16px;
}
.v-btn {
  text-transform: none;
  letter-spacing: 0.5px;
}
</style>

<style scoped>
.client-dialog {
  border-radius: 8px;
}
.client-header {
  height: 64px;
}
.client-title {
  font-size: 24px;
  font-weight: 600;
}
.gradient-text {
  background: linear-gradient(45deg, #6772e5, #3ecf8e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gradient-button {
  background: linear-gradient(45deg, #6772e5, #3ecf8e) !important;
  transition: opacity 0.3s ease;
}
.gradient-button:hover {
  opacity: 0.9;
}
.v-text-field ::v-deep input,
.v-select ::v-deep .v-select__selection {
  font-size: 16px;
}
.v-btn {
  text-transform: none;
  letter-spacing: 0.5px;
}
</style>
